import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAYMENT_INTERVAL, SLUGS_TO_FILTERS } from '../constants/app';
import { APP_ROUTES, getRoute } from '../route';
import { doGetPublicPlansRequest } from '../store/action/challengesAndCourses';
import { setSelectedFilters } from '../store/action/common';
import { doPaymentInitiationRequest } from '../store/action/products';
import { getUserAttribute } from '../utils/helpers';
import { encodeBase64Value, encodeURLParams } from '../utils/url';
import { getItem } from '../utils/cache';

export const useCourseDetail = (
  history,
  match,
  p_interval = PAYMENT_INTERVAL.MONTHLY,
) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [courseDetail, setCourseDetail] = useState(null);
  const [challengeError, setChallengeError] = useState(false);
  const { selectedPlan } = useSelector(state => state.product);

  useEffect(() => {
    const slug = match.params.slug;
    const interval = selectedPlan
      ? selectedPlan?.attributes?.interval
      : p_interval;
    if (slug) {
      dispatch(
        doGetPublicPlansRequest({
          interval,
          callback: plans => {
            const course = plans.find(plan => plan?.attributes?.slug === slug);
            if (course) {
              setCourseDetail(course);
            }
            setIsLoading(false);
          },
        }),
      );
    } else {
      setIsLoading(false);
    }
  }, [match.params.slug, p_interval]);

  const getRedirectionURL = courseDetail => {
    const { card_color, title, plan_type } = courseDetail?.attributes || {};
    const plan_id = courseDetail?.id;
    const params = {
      card_color,
      plan_type,
      title,
    };
    const options = {
      pathParam: {
        id: plan_id,
      },
      searchParam: {
        params: encodeURLParams(params),
      },
    };
    const url =
      plan_type === 'plan'
        ? APP_ROUTES.HOME_SUB_SECTION
        : APP_ROUTES.PREMIUM_COURSES_DETAILS;
    const route = getRoute(url, options);
    return route;
  };

  const getPurchaseKey = plan_type => {
    switch (plan_type) {
      case 'challenge':
        return 'challenges';
      case 'course':
        return 'courses';
      default:
        return plan_type;
    }
  };

  const redirectUser = courseDetail => {
    const { title, product_id, plan_type } = courseDetail?.attributes || {};
    const plan_id = courseDetail?.id;
    const route = getRedirectionURL(courseDetail);
    const purchases = getUserAttribute('purchases');
    const subscriptionDetails = getUserAttribute('subscription_details');
    const { ends_at, subscribed } = subscriptionDetails || {};
    const activeSubscription =
      subscribed && ends_at && new Date() <= new Date(ends_at);
    const isAlreadyPurchased =
      plan_type === 'plan'
        ? activeSubscription
        : purchases?.[getPurchaseKey(plan_type)]?.includes(
            Number(courseDetail?.id),
          );
    if (isAlreadyPurchased) {
      const onBoardingCompleted = getUserAttribute('onboarding_completed');
      if (onBoardingCompleted) {
        history.push(route);
      } else {
        const url = getRoute(APP_ROUTES.FITNESS, {
          searchParam: {
            page: encodeBase64Value(route),
            special_purchase: true,
          },
        });
        history.push(url);
      }
    } else {
      const urlParams = new URLSearchParams(history.location.search);
      const page = urlParams.get('page');
      const slug = match.params.slug || page;
      const payload = {
        product_id,
        plan_id,
        page: encodeBase64Value(route),
        name: encodeURI(title),
        errorCallBack: () => setChallengeError(true),
        plan_type: encodeURI(slug),
      };
      if (plan_type === 'plan' && selectedPlan) delete payload.product_id;
      dispatch(doPaymentInitiationRequest(payload));
    }
  };

  const handleCourseRedirection = () => {
    if (courseDetail) {
      const slug = match.params.slug;
      const selectedFilters = SLUGS_TO_FILTERS[slug];
      if (selectedFilters) {
        dispatch(
          setSelectedFilters({
            selectedFilters,
            callback: () => redirectUser(courseDetail),
          }),
        );
      } else {
        redirectUser(courseDetail);
      }
    } else {
      const urlParams = new URLSearchParams(history.location.search);
      const page = urlParams.get('page');
      const slug = match.params.slug || page;
      const p_interval = getItem('selectedPlan');
      const interval = JSON.parse(p_interval)?.attributes?.interval;
      dispatch(
        doGetPublicPlansRequest({
          interval,
          callback: plans => {
            const course = plans.find(plan => plan?.attributes?.slug === slug);
            if (course) {
              const selectedFilters = SLUGS_TO_FILTERS[slug];
              if (selectedFilters) {
                dispatch(
                  setSelectedFilters({
                    selectedFilters,
                    callback: () => redirectUser(course),
                  }),
                );
              } else {
                redirectUser(course);
              }
            } else {
              history.push(APP_ROUTES.CHALLENGES);
            }
          },
        }),
      );
    }
  };

  return {
    challengeError,
    handleCourseRedirection,
    isLoading,
    setChallengeError,
    courseDetail,
  };
};
