import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { doOtpRequest } from '../../../../store/action/auth';
import Button from '../../../../ui-core/Button/Button';
import { withRouter } from 'react-router';
import { APP_ROUTES, getRoute } from '../../../../route';
import Counter from './Counter';
import { SEGMENT_EVENTS, trackSubmittedEvent } from '../../../../utils/Segment';
import { getUserAttribute } from '../../../../utils/helpers';
import { doPaymentInitiationRequest } from '../../../../store/action/products';
import { useCourseDetail } from '../../../../hooks/useCourseDetail';
// import { toggleShowGiftModal } from '../../../../store/action/gifts';
import { encodeBase64Value, encodeURLParams } from '../../../../utils/url';

import './style.css';

const OtpModal = props => {
  const [postData, setPostData] = useState({
    mobile: null,
    country_code: null,
    otp: null,
  });
  const inputRef = useRef([]);
  const { user } = useSelector(state => state.auth);
  const initialState = new Array(4).fill('');
  const [otp, setOtp] = useState(initialState);
  const { isLoading } = useSelector(state => state.common);
  const { isLoading: loading } = useSelector(state => state.product);
  const userData = user;
  const numLen = user?.attributes?.mobile.toString().length;
  const { handleModalState, history, match } = props;
  let maskInput = 'X';
  for (let i = 0; i < numLen - 5; i++) {
    maskInput = maskInput + 'X';
  }
  const subNum = user?.attributes?.mobile
    .toString()
    .substring(numLen - 4, numLen + 1);
  const dialCode = user?.attributes?.country_code;
  const dispatch = useDispatch();
  const isPayment = history.location.state?.payment || false;
  const { isLoading: challengeDetailLoading, handleCourseRedirection } =
    useCourseDetail(history, match);

  useEffect(() => {
    let isMounted = true;
    setPostData({
      ...postData,
      mobile: userData?.attributes?.mobile,
      country_code: userData?.attributes?.country_code,
    });
    try {
      if (isMounted) {
        setOtp(initialState);
        if (inputRef.current[0] && inputRef.current[0]?.focus)
          inputRef.current[0].focus();
      }
    } catch (e) {
      if (isMounted) {
        throw e;
      }
    }
    return (isMounted = false);
  }, [userData]);

  const handleOtpChange = (element, index) => {
    if (element && isNaN(element.value)) return false;
    const otpData = [
      ...otp.map((d, idx) => (idx === index ? element.value : d)),
    ];

    if (element && otpData[index] !== '') {
      element.classList.add('filled');
    } else {
      element?.classList?.remove('filled');
    }
    setOtp(otpData);
    if (element.value) {
      setTimeout(() => {
        inputRef?.current[index + 1]?.focus();
      });
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.keyCode === 8) {
      setTimeout(() => {
        inputRef?.current[index - 1]?.focus();
        inputRef?.current[index - 1]?.select();
      }, 5);
    } else if (event.keyCode === 13 && otp.every(i => Boolean(i))) {
      handleOtpSubmit();
    }
  };

  const handleRedirectionForAlreadyOnboardedUser = (playbackHistory, plans) => {
    if (playbackHistory?.attributes?.source?.schedule_id) {
      const planId = playbackHistory?.attributes?.source?.id;
      const viewedPlan = plans.find(item => +item.id === +planId);
      const card_color = viewedPlan?.attributes?.card_color;
      const title = viewedPlan?.attributes?.title;
      const plan_type = playbackHistory?.attributes?.source?.plan_type;
      const searchParams = {
        card_color,
        title,
        plan_type,
      };
      if (viewedPlan) {
        if (viewedPlan?.attributes?.plan_type === 'plan') {
          const options = {
            pathParam: { id: planId },
            searchParam: {
              params: encodeURLParams(searchParams),
            },
          };
          const route = getRoute(APP_ROUTES.HOME_SUB_SECTION, options);
          history.push(route);
        } else {
          const options = {
            pathParam: { id: planId },
            searchParam: {
              params: encodeURLParams(searchParams),
            },
          };
          const route = getRoute(APP_ROUTES.PREMIUM_COURSES_DETAILS, options);
          history.push(route);
        }
      } else {
        history.push(APP_ROUTES.HOME);
      }
    } else {
      history.push(APP_ROUTES.HOME);
    }
  };

  const handleOtpSubmit = () => {
    dispatch(
      doOtpRequest({
        data: { ...postData, otp: otp.join('') },
        callback: response => {
          if (user?.attributes?.new_user) {
            trackSubmittedEvent(SEGMENT_EVENTS.USER_SIGNUP_SUCCESSFUL, {
              user_id: user?.id,
              has_access: user?.attributes?.has_access,
              phone: user?.attributes?.mobile,
              country_code: user?.attributes?.country_code,
            });
          } else {
            trackSubmittedEvent(SEGMENT_EVENTS.USER_LOGIN_SUCCESSFUL, {
              user_id: user?.id,
              has_access: user?.attributes?.has_access,
              phone: user?.attributes?.mobile,
              country_code: user?.attributes?.country_code,
            });
          }
          const onboardingCompleted = user?.attributes?.onboarding_completed;

          const { playbackHistory, plans, payment } = response || {};
          if (match.path === APP_ROUTES.GIFTS) {
            if (onboardingCompleted) {
              handleModalState(null);
              // dispatch(toggleShowGiftModal(true));
            } else {
              const url = getRoute(APP_ROUTES.FITNESS, {
                searchParam: {
                  page: encodeBase64Value(APP_ROUTES.GIFTS),
                },
              });
              history.push(url);
              handleModalState(null);
            }
          } else if (match.path === APP_ROUTES.COURSE_SLUG) {
            handleCourseRedirection();
          } else if (match.path === APP_ROUTES.THANK_YOU) {
            handleModalState('refetch');
          } else {
            const subscriptionDetails = getUserAttribute(
              'subscription_details',
            );
            const freeTrial = getUserAttribute('free_trial');
            const isSubscriptionExpired = subscriptionDetails?.ends_at
              ? new Date() > new Date(subscriptionDetails.ends_at)
              : false;
            const noSubscription = !subscriptionDetails?.subscribed;
            const noFreeTrails = !freeTrial;
            const noActiveSubscription = noSubscription ? noFreeTrails : false;
            const isSubscriptionCancelled =
              payment?.attributes?.provider === 'stripe' &&
              payment?.attributes?.original_status === 'cancelled' &&
              (payment?.attributes?.status === 'active' ||
                payment?.attributes?.status === 'cancelled');
            /*
                when user comes into the app through public payment page or start free trial button
                and has no active subscription / free trial or his subscription has expired
                redirect to payment gateway
              */
            if (
              isPayment &&
              (noActiveSubscription ||
                isSubscriptionExpired ||
                isSubscriptionCancelled)
            ) {
              if (!loading) {
                dispatch(
                  doPaymentInitiationRequest({
                    callback: () => handleModalState(null),
                    page: encodeBase64Value(APP_ROUTES.FREE_TRAIL),
                  }),
                );
              }
            } else if (onboardingCompleted) {
              handleRedirectionForAlreadyOnboardedUser(playbackHistory, plans);
            } else {
              history.push(APP_ROUTES.FITNESS, {
                payment: true,
              });
            }
          }
        },
        page: props.page,
        plan_id: props.plan_id,
      }),
    );
  };

  return (
    <div className="form-h">
      <h1 className="otp-h2 text-center mb-3">Enter Code</h1>
      <h3 className="otp-h3 text-center mb-0">
        Enter the verification code sent
      </h3>
      <h3 className="otp-h3 text-center">
        on your Mobile No.{' '}
        <span>
          {dialCode}
          {maskInput}
          {subNum}
        </span>
      </h3>
      <div className="otp-field-wrap d-flex align-items-center justify-content-between">
        {otp.map((data, index) => {
          return (
            <input
              inputMode="numeric"
              pattern="[0-9]*"
              className="otp-field"
              type="text"
              name="otp"
              placeholder="-"
              maxLength="1"
              key={index}
              value={data}
              onChange={evt => handleOtpChange(evt.target, index)}
              onFocus={evt => evt.target.select()}
              onKeyDown={evt => handleKeyDown(evt, index)}
              ref={el => (inputRef.current[index] = el)}
            />
          );
        })}
      </div>

      <Counter
        page={props.page}
        postData={postData}
        otp={otp}
        setOtp={setOtp}
      />

      <Button
        // TODO: we need to add 'spinner-border text-white'
        class={`verify-button ${isLoading && ''}`}
        text="VERIFY"
        isDisabled={
          isLoading ||
          !otp.every(i => Boolean(i)) ||
          loading ||
          challengeDetailLoading
        }
        isLoading={isLoading || loading || challengeDetailLoading}
        onClick={() => handleOtpSubmit()}
      />
    </div>
  );
};

OtpModal.propTypes = {
  handleModalState: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object,
  page: PropTypes.string,
  plan_id: PropTypes.string,
};

export default withRouter(OtpModal);
