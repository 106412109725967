import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
// import Loader from '../../ui-core/Loader';
import './style.css';

const PaymentProgressIndicator = () => {
  const { isPaymentInProgress } = useSelector(state => state.common);

  // if (!isPaymentInProgress) return null;

  return (
    <div className={clsx('payment-progress', !isPaymentInProgress && 'd-none')}>
      <div className="payment-progress-quote">
        <div className="block">
          <h2>
            Good things take time, please don’t hit the back button or refresh
            this screen…..
          </h2>
          <div className="loader">
            <div></div>
          </div>
          <h1>Jen</h1>
        </div>
      </div>
    </div>
  );
};

export default PaymentProgressIndicator;
